'use client'

import { Controller, config } from "@react-spring/core"
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import useEventListener from "@/lib/hooks/useEventListener"
import { useMatchMedia } from "@/lib/hooks/useMatchMedia"
import stopPropogation from "@/lib/utils/stopPropogation"

interface ExpandableZoneProps {
  expandText?: string
  collapseText?: string
  children?: ReactNode
  className?: string
  innerClassName?: string
  buttonClassName?: string
  expandButton?: boolean
  leadingElement?: ReactNode
  dividingElement?: ReactNode
  leadingElementAsToggle?: boolean
  peekOnHover?: boolean
  peekDistance?: number
}

export default function ExpandableZone({
  children,
  expandText = 'read more',
  collapseText = 'read less',
  expandButton = true,
  leadingElement,
  leadingElementAsToggle = false,
  peekOnHover = false,
  peekDistance = 40,
  className,
  innerClassName,
  buttonClassName,
  dividingElement,
}: ExpandableZoneProps) {
  const isTouch = useMatchMedia('(hover: none)')
  const heightRef = useRef(-1)
  const [expanded, setExpanded] = useState(false)
  const [renderExpanded, setRenderExpanded] = useState(false)
  const peekedRef = useRef(false)
  
  const containerRef = useRef<HTMLDivElement>(null)
  const contentContainerRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  
  const heightSpring = useMemo(() => {
    const controller = new Controller({
      height: 0,
      onChange: (x) => {
        window.requestAnimationFrame(() => {
          if (contentContainerRef.current) contentContainerRef.current.style.height = `${x.value.height}px`
        })
      },
      onRest: () => {
        if (controller.springs.height.goal === 0) {
          setRenderExpanded(false)
        }
        if (containerRef.current) containerRef.current.setAttribute('data-expanding', '0')
      },
      config: {
        ...config.slow,
        tension: 180,
      }
    })
    return controller
  }, [])

  const updateSpring = useCallback(() => {
    let height = 0
    if (expanded) height = heightRef.current
    else if (peekedRef.current) height = Math.min(peekDistance, heightRef.current)
    heightSpring.start({ height })
  }, [expanded, heightSpring, peekDistance])

  const setPeeked = useCallback((peeked: boolean) => {
    peekedRef.current = peeked
    if (containerRef.current) {
      containerRef.current.setAttribute('data-peeked', !expanded && peeked ? '1' : '0')
    }
    updateSpring()
  }, [expanded, updateSpring])

  const handleResize = () => {
    if (contentRef.current) {
      const { height } = contentRef.current.getBoundingClientRect()
      heightRef.current = height
      updateSpring()
    }
  }

  const setRefs = (element: HTMLDivElement) => {
    if (element) {
      contentContainerRef.current = element
      contentRef.current = element.querySelector('[data-content]')
      handleResize()
    }
  }

  const handleClick = useCallback(() => {
    setExpanded((currentExpanded) => {
      const newExpanded = !currentExpanded
      if (containerRef.current) containerRef.current.setAttribute('data-expanding', '1')
      if (renderExpanded) updateSpring()
      else setRenderExpanded(true)
      return newExpanded
    })
  }, [renderExpanded, updateSpring])

  const handleMouseEnter = () => {
    if (containerRef.current) containerRef.current.setAttribute('data-expanding', '1')
    setRenderExpanded(true)
    setPeeked(true)
  }

  const handleMouseLeave = () => {
    setPeeked(false)
  }

  useEventListener('resize', handleResize)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.setAttribute('data-expanded', expanded ? '1' : '0')
      containerRef.current.setAttribute('data-peeked', !expanded && peekedRef.current ? '1' : '0')
    }
  }, [expanded])

  return (
    <div
      ref={containerRef}
      className={className}
      onMouseEnter={!isTouch && leadingElementAsToggle && peekOnHover ? handleMouseEnter : undefined}
      onMouseLeave={!isTouch && leadingElementAsToggle && peekOnHover ? handleMouseLeave : undefined}
      onClick={leadingElementAsToggle ? handleClick : undefined}
      data-expanded={0}
      data-peeked={0}
      data-expanding={0}
    >
      {leadingElement}
      <div data-reveal-with-js>
        {renderExpanded && (
          <div
            className={`
              relative h-0 w-full overflow-hidden
              ${innerClassName}`
            }
            ref={setRefs}
          >
            <div
              onClick={expanded ? stopPropogation : undefined}
              data-content
              className={`${expanded ? '' : 'pointer-events-none select-none'}`}
            >
              {children}
            </div>
          </div>
        )}
      </div>
      {dividingElement}
      {expandButton && (
        <div data-reveal-with-js>
          <button
            className={`
              ml-[1.25em] italic opacity-75 hover:opacity-100
              underline decoration-foreground decoration-dotted underline-offset-4 leading-none
              ${buttonClassName}
            `}
            onClick={handleClick}
            data-plain-button
          >
            {expanded ? collapseText : expandText}
          </button>
        </div>
      )}
      <div data-expanded={1} data-remove-with-js>{children}</div>
    </div>
  )
}
