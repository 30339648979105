import(/* webpackMode: "eager" */ "/vercel/path0/components/Button/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ExpandableZone/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/WorkList/workItem.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Intro/intro.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Paragraph/paragraph.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js")